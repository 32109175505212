export const userRoles = {
  ADMIN: 'admin',
  DEVOPS: 'devops',
  OPERATIONS: 'operations',
  VENDOR: 'vendors',
  THERAPISTS:'therapist'
};

export const BATCH = 'Batch';

export const Routes = {
  AUTH: {
    title: '',
    icon: '',
    link: '/auth',
  },
  DASHBOARD: {
    title: 'Dashboard',
    icon: 'dashboard',
    link: '/pages/dashboard',
  },  
  USERS: {
    title: 'Users',
    icon: 'people',
    link: '/pages/users',
  },
  WIDGETS: {
    title: 'Widgets',
    icon: 'widgets',
    link: '/pages/widgets',
  },
  MOBILE_APP_USERS: {
    title: 'Mobile App Users',
    icon: 'people',
    link: '/pages/mobile-app-users',
  },
  CONFIGURATIONS: {
    title: 'Configurations',
    icon: 'settings',
    link: '/pages/points-configurations',
  },
  GROUPS: {
    title: 'Groups',
    icon: 'groups',
    link: '/pages/groups',
  },
  INVITE_MEMBERS: {
    title: 'Invite Members',
    icon: 'group',
    link: '/pages/invite-members',
  },
  PARTNERS: {
    title: 'partners',
    icon: 'person_pin',
    link: '/pages/partners',
  },
  AUDIENCE: {
    title: 'Audiences',
    icon: 'record_voice_over',
    link: '/pages/audiences',
  },
  THERAPISTS:{
    title:'Therapists',
    icon:'people',
    link:'/pages/therapists'
  }
};

export const typeOfNotification: string[] = [
  'reminder',
  'share',
  'profile',
  'score',
];

export const rechargeType: string[] = ['Personal Wallet', 'Enterprise Wallet'];

export const companyName: string[] = ['UB'];
export const testType: string[] = ['saliva-scan'];
export const analyte: Array<string> = ['Cortisol'];
export const status: Array<string> = ['active', 'recall', 'inactive'];

export const TOAST_SUCCESS = 'toast-success';

export const TOAST_ERROR = 'toast-error';

export const TOAST_WARN = 'toast-warn';
