import { default as firebaseConfig } from '@firebase-config';
import {baseUrl} from '../app/core/constants/apiEndpoints/index'


export const environment = {
  production: false,
  useEmulators: false,
  firebase: firebaseConfig.dev,
  apiUrl:baseUrl?.devUrl
};
