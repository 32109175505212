import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';

import {
  AngularFireFunctionsModule,
  REGION,
} from '@angular/fire/compat/functions';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/compat/database';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { AngularFireModule } from '@angular/fire/compat';
import { HttpClientModule } from '@angular/common/http';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

const FIREBASE_EMULATOR_PROVIDERS = [
  {
    provide: USE_AUTH_EMULATOR,
    useValue: environment.useEmulators
      ? ['http://localhost:9099', 9099] // adding the port number to the host name itself because it is not picking up the port number.
      : undefined,
  },
  {
    provide: USE_DATABASE_EMULATOR,
    useValue: environment.useEmulators ? ['localhost', 9000] : undefined,
  },
  {
    provide: USE_FIRESTORE_EMULATOR,
    useValue: environment.useEmulators ? ['localhost', 8080] : undefined,
  },
  {
    provide: USE_FUNCTIONS_EMULATOR,
    useValue: environment.useEmulators ? ['localhost', 5001] : undefined,
  },
];

const ANGULAR_MATERIAL_PROVIDERS = [
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: { appearance: 'outline' },
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    // firebase imports
    AngularFireFunctionsModule,
    AngularFireModule.initializeApp(environment.firebase),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),

    HttpClientModule,
  ],
  providers: [
    ...FIREBASE_EMULATOR_PROVIDERS,
    ...ANGULAR_MATERIAL_PROVIDERS,
    {
      provide: REGION,
      useValue: environment.firebase.locationId ?? 'asia-south1',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}